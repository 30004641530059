import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"

import Title from "../components/Title"
import Attorneys from "../components/Attorneys"
import { CallToAction, Layout, Seo } from "../components"

export const query = graphql`
  {
    allDatoCmsAbout {
      edges {
        node {
          id
          title {
            ... on DatoCmsTitle {
              id
              slug
              title
            }
            ... on DatoCmsInfo {
              id
              info
            }
          }
          seo {
            description
            image {
              gatsbyImageData
            }
            title
          }
        }
      }
    }

    allDatoCmsImage(
      filter: {
        image: {
          title: { eq: "Am House St Alban Vermont" }
          alt: { eq: "Am House St Alban Vermont" }
        }
      }
    ) {
      edges {
        node {
          image {
            alt
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "grey")
            title
          }
        }
      }
    }

    allDatoCmsPageIntro(filter: { title: { eq: "About Page" } }) {
      edges {
        node {
          id
          info
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const {
    allDatoCmsAbout: { edges: about },
    allDatoCmsImage: { edges: image },
    allDatoCmsPageIntro: { edges: intro },
  } = data

  return (
    <Layout>
      {about.map(({ node }) => {
        const { title, description, image } = node.seo
        const seoImage = image.gatsbyImageData.images.src

        return <Seo title={title} description={description} image={seoImage} />
      })}

      <div className="section page-header">
        <Container>
          <SectionHeading>
            <Row>
              <Col>
                <Title title="About Us" />
              </Col>
            </Row>
          </SectionHeading>
        </Container>
      </div>

      <Wrapper className="section">
        <Container>
          <Row>
            <Col>
              {intro.map(({ node }) => {
                return <h3 className="page-intro">{node.info}</h3>
              })}
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              {image.map(({ node }) => {
                const aboutImage = node.image.gatsbyImageData

                return <GatsbyImage image={aboutImage} alt={node.image.alt} />
              })}
            </Col>

            {about.map(({ node }) => (
              <Col md={6}>
                <h3 className="page-title">{node.title[0].title}</h3>
                <div
                  className="card-text-body"
                  dangerouslySetInnerHTML={{
                    __html: node.title[1].info,
                  }}
                ></div>
              </Col>
            ))}
          </Row>
        </Container>
      </Wrapper>

      <Attorneys />

      <CallToAction />
    </Layout>
  )
}

const Wrapper = styled.section`
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .card-text-body {
    text-align: left;
  }

  h3.page-title {
    text-align: left;
    font-weight: 900;
    margin-bottom: 2rem;
  }
`

const SectionHeading = styled.section`
  @media screen and (max-width: 480px) {
    padding: 4rem 0 0 0;
  }

  h2.section-heading {
    text-align: center;
    color: var(--clr-primary-6);
    padding-top: 7rem;

    @media screen and (max-width: 480px) {
      padding-top: 2rem;
    }
  }
`

export default AboutPage
